import { createWebHistory, createRouter } from 'vue-router'

const router = createRouter({
	history: createWebHistory('/'),
	routes: [
		{
			name: 'Main',
			path: '/',
			component: () => import('@/page/Main.vue')
		},
		{
			name: 'Not-found',
			path: '/:pathMatch(.*)*',
			component: () => import('@/page/404.vue')
		},
		{
			name: 'Pruffme',
			path: '/Pruffme',
			component: () => import('@/page/Pruffme.vue')
		},
		{
			name: 'Art',
			path: '/ArtBlock',
			component: () => import('@/page/ArtBlock.vue')
		},
		{
			name: 'Contacts',
			path: '/Contacts',
			component: () => import('@/page/Contacts.vue')
		},
		{
			name: 'Documentation',
			path: '/Documentation',
			component: () => import('@/page/Documentation.vue')
		},
		{
			name: 'ExpertCouncil',
			path: '/ExpertCouncil',
			component: () => import('@/page/ExpertCouncil.vue')
		},
		{
			name: 'GetIn',
			path: '/GetIn',
			component: () => import('@/page/GetIn.vue')
		},
		{
			name: 'Intelligence',
			path: '/Intelligence',
			component: () => import('@/page/Intelligence.vue')
		},
		{
			name: 'StructureAndOrgans',
			path: '/StructureAndOrgans',
			component: () => import('@/page/StructureAndOrgans.vue')
		},
		{
			name: 'Logistics',
			path: '/Logistics',
			component: () => import('@/page/Logistics.vue')
		},
		{
			name: 'VacanciesForAdmission',
			path: '/VacanciesForAdmission',
			component: () => import('@/page/VacanciesForAdmission.vue')
		},
		{
			name: 'Scholarships',
			path: '/Scholarships',
			component: () => import('@/page/Scholarships.vue')
		},
		{
			name: 'Financial',
			path: '/Financial',
			component: () => import('@/page/Financial.vue')
		},
		{
			name: 'Education',
			path: '/Education',
			component: () => import('@/page/Education.vue')
		},
		{
			name: 'Management',
			path: '/Management',
			component: () => import('@/page/Management.vue')
		},
		{
			name: 'Preparation',
			path: '/Preparation',
			component: () => import('@/page/Preparation.vue')
		},
		{
			name: 'ContactsofOlympiad',
			path: '/ContactsofOlympiad',
			component: () => import('@/page/ContactsofOlympiad.vue')
		},
		{
			name: 'BigChallenges2023',
			path: '/BigChallenges2023',
			component: () => import('@/page/BigChallenges2023.vue')
		},
		{
			name: 'BigChallenges2024',
			path: '/BigChallenges2024',
			component: () => import('@/page/BigChallenges2024.vue')
		},
		{
			name: 'DistanceCourses',
			path: '/DistanceCourses',
			component: () => import('@/page/DistanceCourses.vue')
		},
		{
			name: 'SchoolStage',
			path: '/SchoolStage',
			component: () => import('@/page/SchoolStage.vue')
		},
		{
			name: 'News',
			path: '/News',
			component: () => import('@/page/News.vue')
		},
		{
			name: 'BestPrograms',
			path: '/BestPrograms',
			component: () => import('@/page/BestPrograms.vue')
		},
		{
			name: 'OlyandCom',
			path: '/OlyandCom',
			component: () => import('@/page/OlyandCom.vue')
		},
		{
			name: 'Partners',
			path: '/Partners',
			component: () => import('@/page/Partners.vue')
		},
		{
			name: 'Profile',
			path: '/ProfileBlock',
			component: () => import('@/page/ProfileBlock.vue')
		},
		{
			name: 'Registration',
			path: '/Registration',
			component: () => import('@/page/Registration.vue')
		},
		{
			name: 'JuniorProfi',
			path: '/JuniorProfi',
			component: () => import('@/page/JuniorProfi.vue')
		},
		{
			name: 'Remote',
			path: '/RemoteBlock',
			component: () => import('@/page/RemoteBlock.vue')
		},
		{
			name: 'Science',
			path: '/ScienceBlock',
			component: () => import('@/page/ScienceBlock.vue')
		},
		{
			name: 'Sing',
			path: '/Sing',
			component: () => import('@/page/Sing.vue')
		},
		{
			name: 'AllRussian',
			path: '/vsosh2023',
			component: () => import('@/page/AllRussian.vue')
		},
		{
			name: 'Sport',
			path: '/SportBlock',
			component: () => import('@/page/SportBlock.vue')
		},
		{
			name: 'Trustees',
			path: '/Trustees',
			component: () => import('@/page/Trustees.vue')
		},
		{
			name: 'HowToGet',
			path: '/HowToGet',
			component: () => import('@/page/HowToGet.vue')
		},
		{
			name: 'BasicInformation',
			path: '/BasicInformation',
			component: () => import('@/page/BasicInformation.vue')
		},
		{
			name: 'AdditionalGneral',
			path: '/AdditionalGneral',
			component: () => import('@/page/AdditionalGneral.vue')
		},
		{
			name: 'AdditionalGneral2023',
			path: '/AdditionalGneral2023',
			component: () => import('@/page/AdditionalGneral2023.vue')
		},
		{
			name: 'AdditionalGneral2024',
			path: '/AdditionalGneral2024',
			component: () => import('@/page/AdditionalGneral2024.vue')
		},
		{
			name: 'AdditionalGneral2025',
			path: '/AdditionalGneral2025',
			component: () => import('@/page/AdditionalGneral2025.vue')
		},
		{
			name: 'AdditionalProfessionalPrograms',
			path: '/AdditionalProfessionalPrograms',
			component: () => import('@/page/AdditionalProfessionalPrograms.vue')
		},
		{
			name: 'AdditionalProfessionalPrograms2023',
			path: '/AdditionalProfessionalPrograms2023',
			component: () => import('@/page/AdditionalProfessionalPrograms2023.vue')
		},
		{
			name: 'AdditionalProfessionalPrograms2024',
			path: '/AdditionalProfessionalPrograms2024',
			component: () => import('@/page/AdditionalProfessionalPrograms2024.vue')
		},
		{
			name: 'WeeklyCoursesScience',
			path: '/WeeklyCoursesScience',
			component: () => import('@/page/WeeklyCoursesScience.vue')
		},
		{
			name: 'WeeklyCoursesArt',
			path: '/WeeklyCoursesArt',
			component: () => import('@/page/WeeklyCoursesArt.vue')
		},
		{
			name: 'Training',
			path: '/Training',
			component: () => import('@/page/Training.vue')
		},
		{
			name: 'ProfessionalEducation',
			path: '/ProfessionalEducation',
			component: () => import('@/page/ProfessionalEducation.vue')
		},
		{
			name: 'ConferenceSeminars',
			path: '/ConferenceSeminars',
			component: () => import('@/page/ConferenceSeminars.vue')
		},
		{
			name: 'SelectionCriteria',
			path: '/SelectionCriteria',
			component: () => import('@/page/SelectionCriteria.vue')
		},
		{
			name: 'PaidServices',
			path: '/PaidServices',
			component: () => import('@/page/PaidServices.vue')
		},
		{
			name: 'VacantPlaces',
			path: '/VacantPlaces',
			component: () => import('@/page/VacantPlaces.vue')
		},
		{
			name: 'InternatiCooperation',
			path: '/InternatiCooperation',
			component: () => import('@/page/InternatiCooperation.vue')
		},
		{
			name: 'Sirius_Summer',
			path: '/Sirius_Summer',
			component: () => import('@/page/Sirius_Summer.vue')
		},
		{
			name: 'Sirius_Artificial_Intelligence',
			path: '/Sirius_Artificial_Intelligence',
			component: () => import('@/page/Sirius_Artificial_Intelligence.vue')
		},
		{
			name: 'Sirius_Lessons',
			path: '/Sirius_Lessons',
			component: () => import('@/page/Sirius_Lessons.vue')
		},
		{
			name: 'SummerHolidayRecommendations',
			path: '/SummerHolidayRecommendations',
			component: () => import('@/page/SummerHolidayRecommendations.vue')
		},
		{
			name: 'RecommendationsForTeachers',
			path: '/RecommendationsForTeachers',
			component: () => import('@/page/RecommendationsForTeachers.vue')
		},
		{
			name: 'PsychologicalSupport',
			path: '/PsychologicalSupport',
			component: () => import('@/page/PsychologicalSupport.vue')
		},
		{
			name: 'VsoshPage',
			path: '/VsoshPage',
			component: () => import('@/page/VsoshPage.vue')
		},
		{
			name: 'GoldenFundPage',
			path: '/GoldenFundPage',
			component: () => import('@/page/GoldenFundPage.vue')
		},
		{
			name: 'OlympicPage',
			path: '/OlympicPage',
			component: () => import('@/page/OlympicPage.vue')
		},
		{
			name: 'HighestQualityPage',
			path: '/HighestQualityPage',
			component: () => import('@/page/HighestQualityPage.vue')
		},
		{
			name: 'TalentAndSuccessFoundationPage',
			path: '/TalentAndSuccessFoundationPage',
			component: () => import('@/page/TalentAndSuccessFoundationPage.vue')
		},
		{
			name: 'BigChallengesPage',
			path: '/BigChallengesPage',
			component: () => import('@/page/BigChallengesPage.vue')
		},
		{
			name: 'BigChallengesPage2025',
			path: '/BigChallengesPage2025',
			component: () => import('@/page/BigChallengesPage2025.vue')
		},
		{
			name: 'JuniorProfiChampionshipPage',
			path: '/JuniorProfiChampionshipPage',
			component: () => import('@/page/JuniorProfiChampionshipPage.vue')
		},
		{
			name: 'SmartiesAndSmartiesPage',
			path: '/SmartiesAndSmartiesPage',
			component: () => import('@/page/SmartiesAndSmartiesPage.vue')
		},
		{
			name: 'BestStudentPage',
			path: '/BestStudentPage',
			component: () => import('@/page/BestStudentPage.vue')
		},
		{
			name: 'BestCounselorPage',
			path: '/BestCounselorPage',
			component: () => import('@/page/BestCounselorPage.vue')
		},
		{
			name: 'BestPracticsPage',
			path: '/BestPracticsPage',
			component: () => import('@/page/BestPracticsPage.vue')
		},
		{
			name: 'CompetitionsForTeachers',
			path: '/CompetitionsForTeachers',
			component: () => import('@/page/CompetitionsForTeachers.vue')
		},
		{
			name: 'MunicipalCoordinators',
			path: '/MunicipalCoordinators',
			component: () => import('@/page/MunicipalCoordinators.vue')
		},
		{
			name: 'TeachingStaff',
			path: '/TeachingStaff',
			component: () => import('@/page/TeachingStaff.vue')
		},
		{
			name: 'Nutrition',
			path: '/Nutrition',
			component: () => import('@/page/Nutrition.vue')
		},
		{
			name: 'EducationalStandards',
			path: '/EducationalStandards',
			component: () => import('@/page/EducationalStandards.vue')
		},
		{
			name: 'RegulatoryDocuments',
			path: '/RegulatoryDocuments',
			component: () => import('@/page/RegulatoryDocuments.vue')
		},
		{
			name: 'VsoshDocuments',
			path: '/VsoshDocuments',
			component: () => import('@/page/VsoshDocuments.vue')
		},
		{
			name: 'OtherEvents',
			path: '/OtherEvents',
			component: () => import('@/page/OtherEvents.vue')
		},
		{
			name: 'test',
			path: '/test',
			component: () => import('@/page/test.vue')
		},
		{
			name: 'RegionalStage',
			path: '/RegionalStage',
			component: () => import('@/page/RegionalStage.vue')
		},
		{
			name: 'DesignAndExplore',
			path: '/DesignAndExplore',
			component: () => import('@/page/DesignAndExplore.vue')
		},
		{
			name: 'HeritageRussia',
			path: '/HeritageRussia',
			component: () => import('@/page/HeritageRussia.vue')
		},
		{
			name: 'FinalSstageVsosh',
			path: '/FinalSstageVsosh',
			component: () => import('@/page/FinalSstageVsosh.vue')
		},
		{
			name: 'MultiChampionship',
			path: '/MultiChampionship',
			component: () => import('@/page/MultiChampionship.vue')
		},
		{
			name: 'OtherOlympiads',
			path: '/OtherOlympiads',
			component: () => import('@/page/OtherOlympiads.vue')
		},

		// Новости
		{
			name: 'NewsPage1',
			path: '/News/NewsPage1',
			component: () => import('@/NewsPage/NewsPage1')
		},
		{
			name: 'NewsPage2',
			path: '/News/NewsPage2',
			component: () => import('@/NewsPage/NewsPage2')
		},
		{
			name: 'NewsPage3',
			path: '/News/NewsPage3',
			component: () => import('@/NewsPage/NewsPage3')
		},
		{
			name: 'NewsPage4',
			path: '/News/NewsPage4',
			component: () => import('@/NewsPage/NewsPage4')
		},
		{
			name: 'NewsPage5',
			path: '/News/NewsPage5',
			component: () => import('@/NewsPage/NewsPage5')
		},
		{
			name: 'NewsPage6',
			path: '/News/NewsPage6',
			component: () => import('@/NewsPage/NewsPage6')
		},
		{
			name: 'NewsPage7',
			path: '/News/NewsPage7',
			component: () => import('@/NewsPage/NewsPage7')
		},
		{
			name: 'NewsPage8',
			path: '/News/NewsPage8',
			component: () => import('@/NewsPage/NewsPage8')
		},
		{
			name: 'NewsPage9',
			path: '/News/NewsPage9',
			component: () => import('@/NewsPage/NewsPage9')
		},
		{
			name: 'NewsPage10',
			path: '/News/NewsPage10',
			component: () => import('@/NewsPage/NewsPage10')
		},
		{
			name: 'NewsPage11',
			path: '/News/NewsPage11',
			component: () => import('@/NewsPage/NewsPage11')
		},
		{
			name: 'NewsPage12',
			path: '/News/NewsPage12',
			component: () => import('@/NewsPage/NewsPage12')
		},
		{
			name: 'NewsPage13',
			path: '/News/NewsPage13',
			component: () => import('@/NewsPage/NewsPage13')
		},
		{
			name: 'NewsPage14',
			path: '/News/NewsPage14',
			component: () => import('@/NewsPage/NewsPage14')
		},
		{
			name: 'NewsPage15',
			path: '/News/NewsPage15',
			component: () => import('@/NewsPage/NewsPage15')
		},
		{
			name: 'NewsPage16',
			path: '/News/NewsPage16',
			component: () => import('@/NewsPage/NewsPage16')
		},
		{
			name: 'NewsPage17',
			path: '/News/NewsPage17',
			component: () => import('@/NewsPage/NewsPage17')
		},
		{
			name: 'NewsPage18',
			path: '/News/NewsPage18',
			component: () => import('@/NewsPage/NewsPage18')
		},
		{
			name: 'NewsPage19',
			path: '/News/NewsPage19',
			component: () => import('@/NewsPage/NewsPage19')
		},
		{
			name: 'NewsPage20',
			path: '/News/NewsPage20',
			component: () => import('@/NewsPage/NewsPage20')
		},
		{
			name: 'NewsPage21',
			path: '/News/NewsPage21',
			component: () => import('@/NewsPage/NewsPage21')
		},
		{
			name: 'NewsPage22',
			path: '/News/NewsPage22',
			component: () => import('@/NewsPage/NewsPage22')
		},
		{
			name: 'NewsPage23',
			path: '/News/NewsPage23',
			component: () => import('@/NewsPage/NewsPage23')
		},
		{
			name: 'NewsPage24',
			path: '/News/NewsPage24',
			component: () => import('@/NewsPage/NewsPage24')
		},
		{
			name: 'NewsPage25',
			path: '/News/NewsPage25',
			component: () => import('@/NewsPage/NewsPage25')
		},
		{
			name: 'NewsPage26',
			path: '/News/NewsPage26',
			component: () => import('@/NewsPage/NewsPage26')
		},
		{
			name: 'NewsPage27',
			path: '/News/NewsPage27',
			component: () => import('@/NewsPage/NewsPage27')
		},
		{
			name: 'NewsPage28',
			path: '/News/NewsPage28',
			component: () => import('@/NewsPage/NewsPage28')
		},
		{
			name: 'NewsPage29',
			path: '/News/NewsPage29',
			component: () => import('@/NewsPage/NewsPage29')
		},
		{
			name: 'NewsPage30',
			path: '/News/NewsPage30',
			component: () => import('@/NewsPage/NewsPage30')
		},
		{
			name: 'NewsPage31',
			path: '/News/NewsPage31',
			component: () => import('@/NewsPage/NewsPage31')
		},
		{
			name: 'NewsPage32',
			path: '/News/NewsPage32',
			component: () => import('@/NewsPage/NewsPage32')
		},
		{
			name: 'NewsPage33',
			path: '/News/NewsPage33',
			component: () => import('@/NewsPage/NewsPage33')
		},
		{
			name: 'NewsPage34',
			path: '/News/NewsPage34',
			component: () => import('@/NewsPage/NewsPage34')
		},
		{
			name: 'NewsPage35',
			path: '/News/NewsPage35',
			component: () => import('@/NewsPage/NewsPage35')
		},
		{
			name: 'NewsPage36',
			path: '/News/NewsPage36',
			component: () => import('@/NewsPage/NewsPage36')
		},
		{
			name: 'NewsPage37',
			path: '/News/NewsPage37',
			component: () => import('@/NewsPage/NewsPage37')
		},
		{
			name: 'NewsPage38',
			path: '/News/NewsPage38',
			component: () => import('@/NewsPage/NewsPage38')
		},
		{
			name: 'NewsPage39',
			path: '/News/NewsPage39',
			component: () => import('@/NewsPage/NewsPage39')
		},
		{
			name: 'NewsPage40',
			path: '/News/NewsPage40',
			component: () => import('@/NewsPage/NewsPage40')
		},
		{
			name: 'NewsPage41',
			path: '/News/NewsPage41',
			component: () => import('@/NewsPage/NewsPage41')
		},
		{
			name: 'NewsPage42',
			path: '/News/NewsPage42',
			component: () => import('@/NewsPage/NewsPage42')
		},
		{
			name: 'NewsPage43',
			path: '/News/NewsPage43',
			component: () => import('@/NewsPage/NewsPage43')
		},
		{
			name: 'NewsPage44',
			path: '/News/NewsPage44',
			component: () => import('@/NewsPage/NewsPage44')
		},
		{
			name: 'NewsPage45',
			path: '/News/NewsPage45',
			component: () => import('@/NewsPage/NewsPage45')
		},
		{
			name: 'NewsPage46',
			path: '/News/NewsPage46',
			component: () => import('@/NewsPage/NewsPage46')
		},
		{
			name: 'NewsPage47',
			path: '/News/NewsPage47',
			component: () => import('@/NewsPage/NewsPage47')
		},
		{
			name: 'NewsPage48',
			path: '/News/NewsPage48',
			component: () => import('@/NewsPage/NewsPage48')
		},
		{
			name: 'NewsPage49',
			path: '/News/NewsPage49',
			component: () => import('@/NewsPage/NewsPage49')
		},
		{
			name: 'NewsPage50',
			path: '/News/NewsPage50',
			component: () => import('@/NewsPage/NewsPage50')
		},
		{
			name: 'NewsPage51',
			path: '/News/NewsPage51',
			component: () => import('@/NewsPage/NewsPage51')
		},
		{
			name: 'NewsPage52',
			path: '/News/NewsPage52',
			component: () => import('@/NewsPage/NewsPage52')
		},
		{
			name: 'NewsPage53',
			path: '/News/NewsPage53',
			component: () => import('@/NewsPage/NewsPage53')
		},
		{
			name: 'NewsPage54',
			path: '/News/NewsPage54',
			component: () => import('@/NewsPage/NewsPage54')
		},
		{
			name: 'NewsPage55',
			path: '/News/NewsPage55',
			component: () => import('@/NewsPage/NewsPage55')
		},
		{
			name: 'NewsPage56',
			path: '/News/NewsPage56',
			component: () => import('@/NewsPage/NewsPage56')
		},
		{
			name: 'NewsPage57',
			path: '/News/NewsPage57',
			component: () => import('@/NewsPage/NewsPage57')
		},
		{
			name: 'NewsPage58',
			path: '/News/NewsPage58',
			component: () => import('@/NewsPage/NewsPage58')
		},
		{
			name: 'NewsPage59',
			path: '/News/NewsPage59',
			component: () => import('@/NewsPage/NewsPage59')
		},
		{
			name: 'NewsPage60',
			path: '/News/NewsPage60',
			component: () => import('@/NewsPage/NewsPage60')
		},
		{
			name: 'NewsPage61',
			path: '/News/NewsPage61',
			component: () => import('@/NewsPage/NewsPage61')
		},
		{
			name: 'NewsPage62',
			path: '/News/NewsPage62',
			component: () => import('@/NewsPage/NewsPage62')
		},
		{
			name: 'NewsPage63',
			path: '/News/NewsPage63',
			component: () => import('@/NewsPage/NewsPage63')
		},
		{
			name: 'NewsPage64',
			path: '/News/NewsPage64',
			component: () => import('@/NewsPage/NewsPage64')
		},
		{
			name: 'NewsPage65',
			path: '/News/NewsPage65',
			component: () => import('@/NewsPage/NewsPage65')
		},
		{
			name: 'NewsPage66',
			path: '/News/NewsPage66',
			component: () => import('@/NewsPage/NewsPage66')
		},
		{
			name: 'NewsPage67',
			path: '/News/NewsPage67',
			component: () => import('@/NewsPage/NewsPage67')
		},
		{
			name: 'NewsPage68',
			path: '/News/NewsPage68',
			component: () => import('@/NewsPage/NewsPage68')
		},
		{
			name: 'NewsPage69',
			path: '/News/NewsPage69',
			component: () => import('@/NewsPage/NewsPage69')
		},
		{
			name: 'NewsPage70',
			path: '/News/NewsPage70',
			component: () => import('@/NewsPage/NewsPage70')
		},
		{
			name: 'NewsPage71',
			path: '/News/NewsPage71',
			component: () => import('@/NewsPage/NewsPage71')
		},
		{
			name: 'NewsPage72',
			path: '/News/NewsPage72',
			component: () => import('@/NewsPage/NewsPage72')
		},
		{
			name: 'NewsPage73',
			path: '/News/NewsPage73',
			component: () => import('@/NewsPage/NewsPage73')
		},
		{
			name: 'NewsPage74',
			path: '/News/NewsPage74',
			component: () => import('@/NewsPage/NewsPage74')
		},
		{
			name: 'NewsPage75',
			path: '/News/NewsPage75',
			component: () => import('@/NewsPage/NewsPage75')
		},
		{
			name: 'NewsPage76',
			path: '/News/NewsPage76',
			component: () => import('@/NewsPage/NewsPage76')
		},
		{
			name: 'NewsPage77',
			path: '/News/NewsPage77',
			component: () => import('@/NewsPage/NewsPage77')
		},
		{
			name: 'NewsPage78',
			path: '/News/NewsPage78',
			component: () => import('@/NewsPage/NewsPage78')
		},
		{
			name: 'NewsPage79',
			path: '/News/NewsPage79',
			component: () => import('@/NewsPage/NewsPage79')
		},
		{
			name: 'NewsPage80',
			path: '/News/NewsPage80',
			component: () => import('@/NewsPage/NewsPage80')
		},
		{
			name: 'NewsPage81',
			path: '/News/NewsPage81',
			component: () => import('@/NewsPage/NewsPage81')
		},
		{
			name: 'NewsPage82',
			path: '/News/NewsPage82',
			component: () => import('@/NewsPage/NewsPage82')
		},
		{
			name: 'NewsPage83',
			path: '/News/NewsPage83',
			component: () => import('@/NewsPage/NewsPage83')
		},
		{
			name: 'NewsPage84',
			path: '/News/NewsPage84',
			component: () => import('@/NewsPage/NewsPage84')
		},
		{
			name: 'NewsPage85',
			path: '/News/NewsPage85',
			component: () => import('@/NewsPage/NewsPage85')
		},
		{
			name: 'NewsPage86',
			path: '/News/NewsPage86',
			component: () => import('@/NewsPage/NewsPage86')
		},
		{
			name: 'NewsPage87',
			path: '/News/NewsPage87',
			component: () => import('@/NewsPage/NewsPage87')
		},
		{
			name: 'NewsPage88',
			path: '/News/NewsPage88',
			component: () => import('@/NewsPage/NewsPage88')
		},
		{
			name: 'NewsPage89',
			path: '/News/NewsPage89',
			component: () => import('@/NewsPage/NewsPage89')
		},
		{
			name: 'NewsPage90',
			path: '/News/NewsPage90',
			component: () => import('@/NewsPage/NewsPage90')
		},
		{
			name: 'NewsPage91',
			path: '/News/NewsPage91',
			component: () => import('@/NewsPage/NewsPage91')
		},
		{
			name: 'NewsPage92',
			path: '/News/NewsPage92',
			component: () => import('@/NewsPage/NewsPage92')
		},
		{
			name: 'NewsPage93',
			path: '/News/NewsPage93',
			component: () => import('@/NewsPage/NewsPage93')
		},
		{
			name: 'NewsPage94',
			path: '/News/NewsPage94',
			component: () => import('@/NewsPage/NewsPage94')
		},
		{
			name: 'NewsPage95',
			path: '/News/NewsPage95',
			component: () => import('@/NewsPage/NewsPage95')
		},
		{
			name: 'NewsPage96',
			path: '/News/NewsPage96',
			component: () => import('@/NewsPage/NewsPage96')
		},
		{
			name: 'NewsPage97',
			path: '/News/NewsPage97',
			component: () => import('@/NewsPage/NewsPage97')
		},
		{
			name: 'NewsPage98',
			path: '/News/NewsPage98',
			component: () => import('@/NewsPage/NewsPage98')
		},
		{
			name: 'NewsPage99',
			path: '/News/NewsPage99',
			component: () => import('@/NewsPage/NewsPage99')
		},
		{
			name: 'NewsPage100',
			path: '/News/NewsPage100',
			component: () => import('@/NewsPage/NewsPage100')
		},
		{
			name: 'NewsPage101',
			path: '/News/NewsPage101',
			component: () => import('@/NewsPage/NewsPage101')
		},
		{
			name: 'NewsPage102',
			path: '/News/NewsPage102',
			component: () => import('@/NewsPage/NewsPage102')
		},
		{
			name: 'NewsPage103',
			path: '/News/NewsPage103',
			component: () => import('@/NewsPage/NewsPage103')
		},
		{
			name: 'NewsPage104',
			path: '/News/NewsPage104',
			component: () => import('@/NewsPage/NewsPage104')
		},
		{
			name: 'NewsPage105',
			path: '/News/NewsPage105',
			component: () => import('@/NewsPage/NewsPage105')
		},
		{
			name: 'NewsPage106',
			path: '/News/NewsPage106',
			component: () => import('@/NewsPage/NewsPage106')
		},
		{
			name: 'NewsPage107',
			path: '/News/NewsPage107',
			component: () => import('@/NewsPage/NewsPage107')
		},
		{
			name: 'NewsPage108',
			path: '/News/NewsPage108',
			component: () => import('@/NewsPage/NewsPage108')
		},
		{
			name: 'NewsPage109',
			path: '/News/NewsPage109',
			component: () => import('@/NewsPage/NewsPage109')
		},
		{
			name: 'NewsPage110',
			path: '/News/NewsPage110',
			component: () => import('@/NewsPage/NewsPage110')
		},
		{
			name: 'NewsPage111',
			path: '/News/NewsPage111',
			component: () => import('@/NewsPage/NewsPage111')
		},
		{
			name: 'NewsPage112',
			path: '/News/NewsPage112',
			component: () => import('@/NewsPage/NewsPage112')
		},
		{
			name: 'NewsPage113',
			path: '/News/NewsPage113',
			component: () => import('@/NewsPage/NewsPage113')
		},
		{
			name: 'NewsPage114',
			path: '/News/NewsPage114',
			component: () => import('@/NewsPage/NewsPage114')
		},
		{
			name: 'NewsPage115',
			path: '/News/NewsPage115',
			component: () => import('@/NewsPage/NewsPage115')
		},
		{
			name: 'NewsPage116',
			path: '/News/NewsPage116',
			component: () => import('@/NewsPage/NewsPage116')
		},
		{
			name: 'NewsPage117',
			path: '/News/NewsPage117',
			component: () => import('@/NewsPage/NewsPage117')
		},
		{
			name: 'NewsPage118',
			path: '/News/NewsPage118',
			component: () => import('@/NewsPage/NewsPage118')
		},
		{
			name: 'NewsPage119',
			path: '/News/NewsPage119',
			component: () => import('@/NewsPage/NewsPage119')
		},
		{
			name: 'NewsPage120',
			path: '/News/NewsPage120',
			component: () => import('@/NewsPage/NewsPage120')
		},
		{
			name: 'NewsPage121',
			path: '/News/NewsPage121',
			component: () => import('@/NewsPage/NewsPage121')
		},
		{
			name: 'NewsPage122',
			path: '/News/NewsPage122',
			component: () => import('@/NewsPage/NewsPage122')
		},

		// Смены
		{
			name: 'EventsPage1',
			path: '/ProfileBlock/EventsPage1',
			component: () => import('@/EventsPage/EventsPage1')
		},
		{
			name: 'EventsPage2',
			path: '/rk-lv-2023',
			component: () => import('@/EventsPage/EventsPage2')
		},
		{
			name: 'EventsPage3',
			path: '/ProfileBlock/EventsPage3',
			component: () => import('@/EventsPage/EventsPage3')
		},
		{
			name: 'EventsPage4',
			path: '/ProfileBlock/EventsPage4',
			component: () => import('@/EventsPage/EventsPage4')
		},
		{
			name: 'EventsPage5',
			path: '/ProfileBlock/EventsPage5',
			component: () => import('@/EventsPage/EventsPage5')
		},
		{
			name: 'EventsPage6',
			path: '/ProfileBlock/EventsPage6',
			component: () => import('@/EventsPage/EventsPage6')
		},
		{
			name: 'EventsPage7',
			path: '/ProfileBlock/EventsPage7',
			component: () => import('@/EventsPage/EventsPage7')
		},
		{
			name: 'EventsPage8',
			path: '/ProfileBlock/EventsPage8',
			component: () => import('@/EventsPage/EventsPage8')
		},
		{
			name: 'EventsPage9',
			path: '/ProfileBlock/EventsPage9',
			component: () => import('@/EventsPage/EventsPage9')
		},
		{
			name: 'EventsPage10',
			path: '/ProfileBlock/EventsPage10',
			component: () => import('@/EventsPage/EventsPage10')
		},
		{
			name: 'EventsPage11',
			path: '/ProfileBlock/EventsPage11',
			component: () => import('@/EventsPage/EventsPage11')
		},
		{
			name: 'EventsPage12',
			path: '/ProfileBlock/EventsPage12',
			component: () => import('@/EventsPage/EventsPage12')
		},
		{
			name: 'EventsPage13',
			path: '/ProfileBlock/EventsPage13',
			component: () => import('@/EventsPage/EventsPage13')
		},
		{
			name: 'EventsPage14',
			path: '/ProfileBlock/EventsPage14',
			component: () => import('@/EventsPage/EventsPage14')
		},
		{
			name: 'EventsPage15',
			path: '/ProfileBlock/EventsPage15',
			component: () => import('@/EventsPage/EventsPage15')
		},
		{
			name: 'EventsPage16',
			path: '/ProfileBlock/EventsPage16',
			component: () => import('@/EventsPage/EventsPage16')
		},
		{
			name: 'EventsPage17',
			path: '/ProfileBlock/EventsPage17',
			component: () => import('@/EventsPage/EventsPage17')
		},
		{
			name: 'EventsPage18',
			path: '/ProfileBlock/EventsPage18',
			component: () => import('@/EventsPage/EventsPage18')
		},
		{
			name: 'EventsPage19',
			path: '/ProfileBlock/EventsPage19',
			component: () => import('@/EventsPage/EventsPage19')
		},
		{
			name: 'EventsPage20',
			path: '/ProfileBlock/EventsPage20',
			component: () => import('@/EventsPage/EventsPage20')
		},
		{
			name: 'EventsPage21',
			path: '/ProfileBlock/EventsPage21',
			component: () => import('@/EventsPage/EventsPage21')
		},
		{
			name: 'EventsPage22',
			path: '/ProfileBlock/EventsPage22',
			component: () => import('@/EventsPage/EventsPage22')
		},
		{
			name: 'EventsPage23',
			path: '/ProfileBlock/EventsPage23',
			component: () => import('@/EventsPage/EventsPage23')
		},
		{
			name: 'EventsPage24',
			path: '/ProfileBlock/EventsPage24',
			component: () => import('@/EventsPage/EventsPage24')
		},
		{
			name: 'EventsPage25',
			path: '/ProfileBlock/EventsPage25',
			component: () => import('@/EventsPage/EventsPage25')
		},
		{
			name: 'EventsPage26',
			path: '/ProfileBlock/EventsPage26',
			component: () => import('@/EventsPage/EventsPage26')
		},
		{
			name: 'EventsPage27',
			path: '/ProfileBlock/EventsPage27',
			component: () => import('@/EventsPage/EventsPage27')
		},
		{
			name: 'EventsPage28',
			path: '/ProfileBlock/EventsPage28',
			component: () => import('@/EventsPage/EventsPage28')
		},
		{
			name: 'EventsPage29',
			path: '/ProfileBlock/EventsPage29',
			component: () => import('@/EventsPage/EventsPage29')
		},
		{
			name: 'EventsPage30',
			path: '/ProfileBlock/EventsPage30',
			component: () => import('@/EventsPage/EventsPage30')
		},
		{
			name: 'EventsPage31',
			path: '/ProfileBlock/EventsPage31',
			component: () => import('@/EventsPage/EventsPage31')
		},
		{
			name: 'EventsPage32',
			path: '/ProfileBlock/EventsPage32',
			component: () => import('@/EventsPage/EventsPage32')
		},
		{
			name: 'EventsPage33',
			path: '/ProfileBlock/EventsPage33',
			component: () => import('@/EventsPage/EventsPage33')
		},
		{
			name: 'EventsPage36',
			path: '/ProfileBlock/EventsPage36',
			component: () => import('@/EventsPage/EventsPage36')
		},
		{
			name: 'EventsPage37',
			path: '/ProfileBlock/EventsPage37',
			component: () => import('@/EventsPage/EventsPage37')
		},
		{
			name: 'EventsPage38',
			path: '/ProfileBlock/EventsPage38',
			component: () => import('@/EventsPage/EventsPage38')
		},
		{
			name: 'EventsPage39',
			path: '/ProfileBlock/EventsPage39',
			component: () => import('@/EventsPage/EventsPage39')
		},
		{
			name: 'EventsPage40',
			path: '/ProfileBlock/EventsPage40',
			component: () => import('@/EventsPage/EventsPage40')
		},
		{
			name: 'EventsPage41',
			path: '/ProfileBlock/EventsPage41',
			component: () => import('@/EventsPage/EventsPage41')
		},
		{
			name: 'EventsPage42',
			path: '/ProfileBlock/EventsPage42',
			component: () => import('@/EventsPage/EventsPage42')
		},
		{
			name: 'EventsPage46',
			path: '/ProfileBlock/EventsPage46',
			component: () => import('@/EventsPage/EventsPage46')
		},
		{
			name: 'EventsPage47',
			path: '/ProfileBlock/EventsPage47',
			component: () => import('@/EventsPage/EventsPage47')
		},
		{
			name: 'EventsPage48',
			path: '/ProfileBlock/EventsPage48',
			component: () => import('@/EventsPage/EventsPage48')
		},
		{
			name: 'EventsPage49',
			path: '/ProfileBlock/EventsPage49',
			component: () => import('@/EventsPage/EventsPage49')
		},
		{
			name: 'EventsPage50',
			path: '/ProfileBlock/EventsPage50',
			component: () => import('@/EventsPage/EventsPage50')
		},
		{
			name: 'EventsPage51',
			path: '/ProfileBlock/EventsPage51',
			component: () => import('@/EventsPage/EventsPage51')
		},
		{
			name: 'EventsPage52',
			path: '/ProfileBlock/EventsPage52',
			component: () => import('@/EventsPage/EventsPage52')
		},
		{
			name: 'EventsPage53',
			path: '/ProfileBlock/EventsPage53',
			component: () => import('@/EventsPage/EventsPage53')
		},
		{
			name: 'EventsPage54',
			path: '/ProfileBlock/EventsPage54',
			component: () => import('@/EventsPage/EventsPage54')
		},
		{
			name: 'EventsPage55',
			path: '/ProfileBlock/EventsPage55',
			component: () => import('@/EventsPage/EventsPage55')
		},
		{
			name: 'EventsPage56',
			path: '/ProfileBlock/EventsPage56',
			component: () => import('@/EventsPage/EventsPage56')
		},
		{
			name: 'EventsPage57',
			path: '/ProfileBlock/EventsPage57',
			component: () => import('@/EventsPage/EventsPage57')
		},
		{
			name: 'EventsPage58',
			path: '/ProfileBlock/EventsPage58',
			component: () => import('@/EventsPage/EventsPage58')
		},
		{
			name: 'EventsPage59',
			path: '/ProfileBlock/EventsPage59',
			component: () => import('@/EventsPage/EventsPage59')
		},
		{
			name: 'EventsPage90',
			path: '/ProfileBlock/EventsPage90',
			component: () => import('@/EventsPage/EventsPage90')
		},
		{
			name: 'EventsPage91',
			path: '/ProfileBlock/EventsPage91',
			component: () => import('@/EventsPage/EventsPage91')
		},
		{
			name: 'EventsPage92',
			path: '/ProfileBlock/EventsPage92',
			component: () => import('@/EventsPage/EventsPage92')
		},
		{
			name: 'EventsPage93',
			path: '/ProfileBlock/EventsPage93',
			component: () => import('@/EventsPage/EventsPage93')
		},
		{
			name: 'EventsPage94',
			path: '/ProfileBlock/EventsPage94',
			component: () => import('@/EventsPage/EventsPage94')
		},
		{
			name: 'EventsPage95',
			path: '/ProfileBlock/EventsPage95',
			component: () => import('@/EventsPage/EventsPage95')
		},
		{
			name: 'EventsPage96',
			path: '/ProfileBlock/EventsPage96',
			component: () => import('@/EventsPage/EventsPage96')
		},
		{
			name: 'EventsPage97',
			path: '/ProfileBlock/EventsPage97',
			component: () => import('@/EventsPage/EventsPage97')
		},
		{
			name: 'EventsPage98',
			path: '/ProfileBlock/EventsPage98',
			component: () => import('@/EventsPage/EventsPage98')
		},
		{
			name: 'EventsPage99',
			path: '/ProfileBlock/EventsPage99',
			component: () => import('@/EventsPage/EventsPage99')
		},
		{
			name: 'EventsPage100',
			path: '/ProfileBlock/EventsPage100',
			component: () => import('@/EventsPage/EventsPage100')
		},
		{
			name: 'EventsPage102',
			path: '/ProfileBlock/EventsPage102',
			component: () => import('@/EventsPage/EventsPage102')
		},
		{
			name: 'EventsPage103',
			path: '/ProfileBlock/EventsPage103',
			component: () => import('@/EventsPage/EventsPage103')
		},
		{
			name: 'EventsPage104',
			path: '/ProfileBlock/EventsPage104',
			component: () => import('@/EventsPage/EventsPage104')
		},
		{
			name: 'EventsPage105',
			path: '/ProfileBlock/EventsPage105',
			component: () => import('@/EventsPage/EventsPage105')
		},
		{
			name: 'EventsPage106',
			path: '/ProfileBlock/EventsPage106',
			component: () => import('@/EventsPage/EventsPage106')
		},
		{
			name: 'EventsPage107',
			path: '/ProfileBlock/EventsPage107',
			component: () => import('@/EventsPage/EventsPage107')
		},
		{
			name: 'EventsPage108',
			path: '/ProfileBlock/EventsPage108',
			component: () => import('@/EventsPage/EventsPage108')
		},
		{
			name: 'EventsPage109',
			path: '/ProfileBlock/EventsPage109',
			component: () => import('@/EventsPage/EventsPage109')
		},
		{
			name: 'EventsPage111',
			path: '/ProfileBlock/EventsPage111',
			component: () => import('@/EventsPage/EventsPage111')
		},
		{
			name: 'EventsPage112',
			path: '/ProfileBlock/EventsPage112',
			component: () => import('@/EventsPage/EventsPage112')
		},
		{
			name: 'EventsPage114',
			path: '/ProfileBlock/EventsPage114',
			component: () => import('@/EventsPage/EventsPage114')
		},
		{
			name: 'EventsPage115',
			path: '/ProfileBlock/EventsPage115',
			component: () => import('@/EventsPage/EventsPage115')
		},
		{
			name: 'EventsPage116',
			path: '/ProfileBlock/EventsPage116',
			component: () => import('@/EventsPage/EventsPage116')
		},
		{
			name: 'EventsPage117',
			path: '/ProfileBlock/EventsPage117',
			component: () => import('@/EventsPage/EventsPage117')
		},
		{
			name: 'EventsPage118',
			path: '/ProfileBlock/EventsPage118',
			component: () => import('@/EventsPage/EventsPage118')
		},
		{
			name: 'EventsPage119',
			path: '/ProfileBlock/EventsPage119',
			component: () => import('@/EventsPage/EventsPage119')
		},
		{
			name: 'EventsPage120',
			path: '/ProfileBlock/EventsPage120',
			component: () => import('@/EventsPage/EventsPage120')
		},
		{
			name: 'EventsPage121',
			path: '/ProfileBlock/EventsPage121',
			component: () => import('@/EventsPage/EventsPage121')
		},
		{
			name: 'EventsPage122',
			path: '/ProfileBlock/EventsPage122',
			component: () => import('@/EventsPage/EventsPage122')
		},
		{
			name: 'EventsPage123',
			path: '/ProfileBlock/EventsPage123',
			component: () => import('@/EventsPage/EventsPage123')
		},
		{
			name: 'EventsPage124',
			path: '/ProfileBlock/EventsPage124',
			component: () => import('@/EventsPage/EventsPage124')
		},
		{
			name: 'EventsPage125',
			path: '/ProfileBlock/EventsPage125',
			component: () => import('@/EventsPage/EventsPage125')
		},
		{
			name: 'EventsPage126',
			path: '/ProfileBlock/EventsPage126',
			component: () => import('@/EventsPage/EventsPage126')
		},
		{
			name: 'EventsPage127',
			path: '/ProfileBlock/EventsPage127',
			component: () => import('@/EventsPage/EventsPage127')
		},
		{
			name: 'EventsPage128',
			path: '/ProfileBlock/EventsPage128',
			component: () => import('@/EventsPage/EventsPage128')
		},
		{
			name: 'EventsPage129',
			path: '/ProfileBlock/EventsPage129',
			component: () => import('@/EventsPage/EventsPage129')
		},
		{
			name: 'EventsPage131',
			path: '/ProfileBlock/EventsPage131',
			component: () => import('@/EventsPage/EventsPage131')
		},
		{
			name: 'EventsPage132',
			path: '/ProfileBlock/EventsPage132',
			component: () => import('@/EventsPage/EventsPage132')
		},
		{
			name: 'EventsPage133',
			path: '/ProfileBlock/EventsPage133',
			component: () => import('@/EventsPage/EventsPage133')
		},
		{
			name: 'EventsPage135',
			path: '/ProfileBlock/EventsPage135',
			component: () => import('@/EventsPage/EventsPage135')
		},
		{
			name: 'EventsPage136',
			path: '/ProfileBlock/EventsPage136',
			component: () => import('@/EventsPage/EventsPage136')
		},
		{
			name: 'EventsPage137',
			path: '/ProfileBlock/EventsPage137',
			component: () => import('@/EventsPage/EventsPage137')
		},
		{
			name: 'EventsPage138',
			path: '/ProfileBlock/EventsPage138',
			component: () => import('@/EventsPage/EventsPage138')
		},
		{
			name: 'EventsPage139',
			path: '/ProfileBlock/EventsPage139',
			component: () => import('@/EventsPage/EventsPage139')
		},
		{
			name: 'EventsPage140',
			path: '/ProfileBlock/EventsPage140',
			component: () => import('@/EventsPage/EventsPage140')
		},
		{
			name: 'EventsPage141',
			path: '/ProfileBlock/EventsPage141',
			component: () => import('@/EventsPage/EventsPage141')
		},
		{
			name: 'EventsPage142',
			path: '/ProfileBlock/EventsPage142',
			component: () => import('@/EventsPage/EventsPage142')
		},
		{
			name: 'EventsPage143',
			path: '/ProfileBlock/EventsPage143',
			component: () => import('@/EventsPage/EventsPage143')
		},
		{
			name: 'EventsPage144',
			path: '/ProfileBlock/EventsPage144',
			component: () => import('@/EventsPage/EventsPage144')
		},
		{
			name: 'EventsPage145',
			path: '/ProfileBlock/EventsPage145',
			component: () => import('@/EventsPage/EventsPage145')
		},
		{
			name: 'EventsPage146',
			path: '/ProfileBlock/EventsPage146',
			component: () => import('@/EventsPage/EventsPage146')
		},
		{
			name: 'EventsPage147',
			path: '/ProfileBlock/EventsPage147',
			component: () => import('@/EventsPage/EventsPage147')
		},
		{
			name: 'EventsPage148',
			path: '/ProfileBlock/EventsPage148',
			component: () => import('@/EventsPage/EventsPage148')
		},
		{
			name: 'EventsPage149',
			path: '/ProfileBlock/EventsPage149',
			component: () => import('@/EventsPage/EventsPage149')
		},
		{
			name: 'EventsPage150',
			path: '/ProfileBlock/EventsPage150',
			component: () => import('@/EventsPage/EventsPage150')
		},
		{
			name: 'EventsPage151',
			path: '/ProfileBlock/EventsPage151',
			component: () => import('@/EventsPage/EventsPage151')
		},
		{
			name: 'EventsPage152',
			path: '/ProfileBlock/EventsPage152',
			component: () => import('@/EventsPage/EventsPage152')
		},
		{
			name: 'EventsPage153',
			path: '/ProfileBlock/EventsPage153',
			component: () => import('@/EventsPage/EventsPage153')
		},
		{
			name: 'EventsPage154',
			path: '/ProfileBlock/EventsPage154',
			component: () => import('@/EventsPage/EventsPage154')
		},
		{
			name: 'EventsPage156',
			path: '/ProfileBlock/EventsPage156',
			component: () => import('@/EventsPage/EventsPage156')
		},
		{
			name: 'EventsPage157',
			path: '/ProfileBlock/EventsPage157',
			component: () => import('@/EventsPage/EventsPage157')
		},
		{
			name: 'EventsPage158',
			path: '/ProfileBlock/EventsPage158',
			component: () => import('@/EventsPage/EventsPage158')
		},
		{
			name: 'EventsPage159',
			path: '/ProfileBlock/EventsPage159',
			component: () => import('@/EventsPage/EventsPage159')
		},
		{
			name: 'EventsPage160',
			path: '/ProfileBlock/EventsPage160',
			component: () => import('@/EventsPage/EventsPage160')
		},
		{
			name: 'EventsPage161',
			path: '/ProfileBlock/EventsPage161',
			component: () => import('@/EventsPage/EventsPage161')
		},
		{
			name: 'EventsPage162',
			path: '/ProfileBlock/EventsPage162',
			component: () => import('@/EventsPage/EventsPage162')
		},
		{
			name: 'EventsPage163',
			path: '/ProfileBlock/EventsPage163',
			component: () => import('@/EventsPage/EventsPage163')
		},
		{
			name: 'EventsPage164',
			path: '/ProfileBlock/EventsPage164',
			component: () => import('@/EventsPage/EventsPage164')
		},
		{
			name: 'EventsPage165',
			path: '/ProfileBlock/EventsPage165',
			component: () => import('@/EventsPage/EventsPage165')
		},
		{
			name: 'EventsPage166',
			path: '/ProfileBlock/EventsPage166',
			component: () => import('@/EventsPage/EventsPage166')
		},
		{
			name: 'EventsPage167',
			path: '/ProfileBlock/EventsPage167',
			component: () => import('@/EventsPage/EventsPage167')
		},
		{
			name: 'EventsPage168',
			path: '/ProfileBlock/EventsPage168',
			component: () => import('@/EventsPage/EventsPage168')
		},
		{
			name: 'EventsPage169',
			path: '/ProfileBlock/EventsPage169',
			component: () => import('@/EventsPage/EventsPage169')
		},
		{
			name: 'EventsPage170',
			path: '/ProfileBlock/EventsPage170',
			component: () => import('@/EventsPage/EventsPage170')
		},
		{
			name: 'EventsPage171',
			path: '/ProfileBlock/EventsPage171',
			component: () => import('@/EventsPage/EventsPage171')
		},
		{
			name: 'EventsPage172',
			path: '/ProfileBlock/EventsPage172',
			component: () => import('@/EventsPage/EventsPage172')
		},
		{
			name: 'EventsPage173',
			path: '/ProfileBlock/EventsPage173',
			component: () => import('@/EventsPage/EventsPage173')
		},
		{
			name: 'EventsPage174',
			path: '/ProfileBlock/EventsPage174',
			component: () => import('@/EventsPage/EventsPage174')
		},
		{
			name: 'EventsPage175',
			path: '/ProfileBlock/EventsPage175',
			component: () => import('@/EventsPage/EventsPage175')
		},
		{
			name: 'EventsPage176',
			path: '/ProfileBlock/EventsPage176',
			component: () => import('@/EventsPage/EventsPage176')
		},
		{
			name: 'EventsPage177',
			path: '/ProfileBlock/EventsPage177',
			component: () => import('@/EventsPage/EventsPage177')
		},
		{
			name: 'EventsPage178',
			path: '/ProfileBlock/EventsPage178',
			component: () => import('@/EventsPage/EventsPage178')
		},
		{
			name: 'EventsPage179',
			path: '/ProfileBlock/EventsPage179',
			component: () => import('@/EventsPage/EventsPage179')
		},
		{
			name: 'EventsPage180',
			path: '/ProfileBlock/EventsPage180',
			component: () => import('@/EventsPage/EventsPage180')
		},
		{
			name: 'EventsPage181',
			path: '/ProfileBlock/EventsPage181',
			component: () => import('@/EventsPage/EventsPage181')
		},
		{
			name: 'EventsPage182',
			path: '/ProfileBlock/EventsPage182',
			component: () => import('@/EventsPage/EventsPage182')
		},
		{
			name: 'EventsPage183',
			path: '/ProfileBlock/EventsPage183',
			component: () => import('@/EventsPage/EventsPage183')
		},
		{
			name: 'EventsPage184',
			path: '/ProfileBlock/EventsPage184',
			component: () => import('@/EventsPage/EventsPage184')
		},
		{
			name: 'EventsPage185',
			path: '/ProfileBlock/EventsPage185',
			component: () => import('@/EventsPage/EventsPage185')
		},
		{
			name: 'EventsPage186',
			path: '/ProfileBlock/EventsPage186',
			component: () => import('@/EventsPage/EventsPage186')
		},
		{
			name: 'EventsPage187',
			path: '/ProfileBlock/EventsPage187',
			component: () => import('@/EventsPage/EventsPage187')
		},
		{
			name: 'EventsPage188',
			path: '/ProfileBlock/EventsPage188',
			component: () => import('@/EventsPage/EventsPage188')
		},
		{
			name: 'EventsPage189',
			path: '/ProfileBlock/EventsPage189',
			component: () => import('@/EventsPage/EventsPage189')
		},
		{
			name: 'EventsPage190',
			path: '/ProfileBlock/EventsPage190',
			component: () => import('@/EventsPage/EventsPage190')
		},
		{
			name: 'EventsPage191',
			path: '/ProfileBlock/EventsPage191',
			component: () => import('@/EventsPage/EventsPage191')
		},
		{
			name: 'EventsPage192',
			path: '/ProfileBlock/EventsPage192',
			component: () => import('@/EventsPage/EventsPage192')
		},
		{
			name: 'EventsPage193',
			path: '/ProfileBlock/EventsPage193',
			component: () => import('@/EventsPage/EventsPage193')
		},
		{
			name: 'EventsPage194',
			path: '/ProfileBlock/EventsPage194',
			component: () => import('@/EventsPage/EventsPage194')
		},
		{
			name: 'EventsPage195',
			path: '/ProfileBlock/EventsPage195',
			component: () => import('@/EventsPage/EventsPage195')
		},
		{
			name: 'EventsPage196',
			path: '/ProfileBlock/EventsPage196',
			component: () => import('@/EventsPage/EventsPage196')
		},
		{
			name: 'EventsPage197',
			path: '/ProfileBlock/EventsPage197',
			component: () => import('@/EventsPage/EventsPage197')
		},
		{
			name: 'EventsPage198',
			path: '/ProfileBlock/EventsPage198',
			component: () => import('@/EventsPage/EventsPage198')
		},
		{
			name: 'EventsPage199',
			path: '/ProfileBlock/EventsPage199',
			component: () => import('@/EventsPage/EventsPage199')
		},
		{
			name: 'EventsPage200',
			path: '/ProfileBlock/EventsPage200',
			component: () => import('@/EventsPage/EventsPage200')
		},
		{
			name: 'EventsPage201',
			path: '/ProfileBlock/EventsPage201',
			component: () => import('@/EventsPage/EventsPage201')
		},
		{
			name: 'EventsPage202',
			path: '/ProfileBlock/EventsPage202',
			component: () => import('@/EventsPage/EventsPage202')
		},
		{
			name: 'EventsPage203',
			path: '/ProfileBlock/EventsPage203',
			component: () => import('@/EventsPage/EventsPage203')
		},
		{
			name: 'EventsPage204',
			path: '/ProfileBlock/EventsPage204',
			component: () => import('@/EventsPage/EventsPage204')
		},
		{
			name: 'EventsPage205',
			path: '/ProfileBlock/EventsPage205',
			component: () => import('@/EventsPage/EventsPage205')
		},
		{
			name: 'EventsPage206',
			path: '/ProfileBlock/EventsPage206',
			component: () => import('@/EventsPage/EventsPage206')
		},
		{
			name: 'EventsPage207',
			path: '/ProfileBlock/EventsPage207',
			component: () => import('@/EventsPage/EventsPage207')
		},
		{
			name: 'EventsPage208',
			path: '/ProfileBlock/EventsPage208',
			component: () => import('@/EventsPage/EventsPage208')
		},
		{
			name: 'EventsPage209',
			path: '/ProfileBlock/EventsPage209',
			component: () => import('@/EventsPage/EventsPage209')
		},
		{
			name: 'EventsPage210',
			path: '/ProfileBlock/EventsPage210',
			component: () => import('@/EventsPage/EventsPage210')
		},
		{
			name: 'EventsPage211',
			path: '/ProfileBlock/EventsPage211',
			component: () => import('@/EventsPage/EventsPage211')
		},
		{
			name: 'EventsPage212',
			path: '/ProfileBlock/EventsPage212',
			component: () => import('@/EventsPage/EventsPage212')
		},
		{
			name: 'EventsPage215',
			path: '/ProfileBlock/EventsPage215',
			component: () => import('@/EventsPage/EventsPage215')
		},
		{
			name: 'EventsPage216',
			path: '/ProfileBlock/EventsPage216',
			component: () => import('@/EventsPage/EventsPage216')
		},
		{
			name: 'EventsPage217',
			path: '/ProfileBlock/EventsPage217',
			component: () => import('@/EventsPage/EventsPage217')
		},
		{
			name: 'EventsPage218',
			path: '/ProfileBlock/EventsPage218',
			component: () => import('@/EventsPage/EventsPage218')
		},
		{
			name: 'EventsPage219',
			path: '/ProfileBlock/EventsPage219',
			component: () => import('@/EventsPage/EventsPage219')
		},
		{
			name: 'EventsPage220',
			path: '/ProfileBlock/EventsPage220',
			component: () => import('@/EventsPage/EventsPage220')
		},
		{
			name: 'EventsPage221',
			path: '/ProfileBlock/EventsPage221',
			component: () => import('@/EventsPage/EventsPage221')
		},
		{
			name: 'EventsPage222',
			path: '/ProfileBlock/EventsPage222',
			component: () => import('@/EventsPage/EventsPage222')
		},
		{
			name: 'EventsPage223',
			path: '/ProfileBlock/EventsPage223',
			component: () => import('@/EventsPage/EventsPage223')
		},
		{
			name: 'EventsPage224',
			path: '/ProfileBlock/EventsPage224',
			component: () => import('@/EventsPage/EventsPage224')
		},
		{
			name: 'EventsPage225',
			path: '/ProfileBlock/EventsPage225',
			component: () => import('@/EventsPage/EventsPage225')
		},
		{
			name: 'EventsPage226',
			path: '/ProfileBlock/EventsPage226',
			component: () => import('@/EventsPage/EventsPage226')
		},
		{
			name: 'EventsPage227',
			path: '/ProfileBlock/EventsPage227',
			component: () => import('@/EventsPage/EventsPage227')
		},
		{
			name: 'EventsPage228',
			path: '/ProfileBlock/EventsPage228',
			component: () => import('@/EventsPage/EventsPage228')
		},
		{
			name: 'EventsPage229',
			path: '/ProfileBlock/EventsPage229',
			component: () => import('@/EventsPage/EventsPage229')
		},
		{
			name: 'EventsPage230',
			path: '/ProfileBlock/EventsPage230',
			component: () => import('@/EventsPage/EventsPage230')
		},
		{
			name: 'EventsPage231',
			path: '/ProfileBlock/EventsPage231',
			component: () => import('@/EventsPage/EventsPage231')
		},
		{
			name: 'EventsPage232',
			path: '/ProfileBlock/EventsPage232',
			component: () => import('@/EventsPage/EventsPage232')
		},
		{
			name: 'EventsPage233',
			path: '/ProfileBlock/EventsPage233',
			component: () => import('@/EventsPage/EventsPage233')
		},
		{
			name: 'EventsPage234',
			path: '/ProfileBlock/EventsPage234',
			component: () => import('@/EventsPage/EventsPage234')
		},
		{
			name: 'EventsPage235',
			path: '/ProfileBlock/EventsPage235',
			component: () => import('@/EventsPage/EventsPage235')
		},
		{
			name: 'EventsPage236',
			path: '/ProfileBlock/EventsPage236',
			component: () => import('@/EventsPage/EventsPage236')
		},
		{
			name: 'EventsPage237',
			path: '/ProfileBlock/EventsPage237',
			component: () => import('@/EventsPage/EventsPage237')
		},
		{
			name: 'EventsPage238',
			path: '/ProfileBlock/EventsPage238',
			component: () => import('@/EventsPage/EventsPage238')
		},
		{
			name: 'EventsPage239',
			path: '/ProfileBlock/EventsPage239',
			component: () => import('@/EventsPage/EventsPage239')
		},
		{
			name: 'EventsPage240',
			path: '/ProfileBlock/EventsPage240',
			component: () => import('@/EventsPage/EventsPage240')
		},
		{
			name: 'EventsPage241',
			path: '/ProfileBlock/EventsPage241',
			component: () => import('@/EventsPage/EventsPage241')
		},
		{
			name: 'EventsPage242',
			path: '/ProfileBlock/EventsPage242',
			component: () => import('@/EventsPage/EventsPage242')
		},
		{
			name: 'EventsPage244',
			path: '/ProfileBlock/EventsPage244',
			component: () => import('@/EventsPage/EventsPage244')
		},
		{
			name: 'EventsPage245',
			path: '/ProfileBlock/EventsPage245',
			component: () => import('@/EventsPage/EventsPage245')
		},
		{
			name: 'EventsPage246',
			path: '/ProfileBlock/EventsPage246',
			component: () => import('@/EventsPage/EventsPage246')
		},
		{
			name: 'EventsPage247',
			path: '/ProfileBlock/EventsPage247',
			component: () => import('@/EventsPage/EventsPage247')
		},
		{
			name: 'EventsPage248',
			path: '/ProfileBlock/EventsPage248',
			component: () => import('@/EventsPage/EventsPage248')
		},
		{
			name: 'EventsPage249',
			path: '/ProfileBlock/EventsPage249',
			component: () => import('@/EventsPage/EventsPage249')
		},
		{
			name: 'EventsPage250',
			path: '/ProfileBlock/EventsPage250',
			component: () => import('@/EventsPage/EventsPage250')
		},
		{
			name: 'EventsPage251',
			path: '/ProfileBlock/EventsPage251',
			component: () => import('@/EventsPage/EventsPage251')
		},
		{
			name: 'EventsPage253',
			path: '/ProfileBlock/EventsPage253',
			component: () => import('@/EventsPage/EventsPage253')
		},
		{
			name: 'EventsPage254',
			path: '/ProfileBlock/EventsPage254',
			component: () => import('@/EventsPage/EventsPage254')
		},
		{
			name: 'EventsPage255',
			path: '/ProfileBlock/EventsPage255',
			component: () => import('@/EventsPage/EventsPage255')
		},
		{
			name: 'EventsPage256',
			path: '/ProfileBlock/EventsPage256',
			component: () => import('@/EventsPage/EventsPage256')
		},
		{
			name: 'EventsPage257',
			path: '/ProfileBlock/EventsPage257',
			component: () => import('@/EventsPage/EventsPage257')
		},
		{
			name: 'EventsPage258',
			path: '/ProfileBlock/EventsPage258',
			component: () => import('@/EventsPage/EventsPage258')
		},
		{
			name: 'EventsPage259',
			path: '/ProfileBlock/EventsPage259',
			component: () => import('@/EventsPage/EventsPage259')
		},
		{
			name: 'EventsPage260',
			path: '/ProfileBlock/EventsPage260',
			component: () => import('@/EventsPage/EventsPage260')
		},
		{
			name: 'EventsPage261',
			path: '/ProfileBlock/EventsPage261',
			component: () => import('@/EventsPage/EventsPage261')
		},
		{
			name: 'EventsPage262',
			path: '/ProfileBlock/EventsPage262',
			component: () => import('@/EventsPage/EventsPage262')
		},
		{
			name: 'EventsPage263',
			path: '/ProfileBlock/EventsPage263',
			component: () => import('@/EventsPage/EventsPage263')
		},
		{
			name: 'EventsPage264',
			path: '/ProfileBlock/EventsPage264',
			component: () => import('@/EventsPage/EventsPage264')
		},
		{
			name: 'EventsPage265',
			path: '/ProfileBlock/EventsPage265',
			component: () => import('@/EventsPage/EventsPage265')
		},
		{
			name: 'EventsPage266',
			path: '/ProfileBlock/EventsPage266',
			component: () => import('@/EventsPage/EventsPage266')
		},
		{
			name: 'EventsPage267',
			path: '/ProfileBlock/EventsPage267',
			component: () => import('@/EventsPage/EventsPage267')
		},
		{
			name: 'EventsPage268',
			path: '/ProfileBlock/EventsPage268',
			component: () => import('@/EventsPage/EventsPage268')
		},
		{
			name: 'EventsPage269',
			path: '/ProfileBlock/EventsPage269',
			component: () => import('@/EventsPage/EventsPage269')
		},
		{
			name: 'EventsPage270',
			path: '/ProfileBlock/EventsPage270',
			component: () => import('@/EventsPage/EventsPage270')
		},
		{
			name: 'EventsPage271',
			path: '/ProfileBlock/EventsPage271',
			component: () => import('@/EventsPage/EventsPage271')
		},
		{
			name: 'EventsPage272',
			path: '/ProfileBlock/EventsPage272',
			component: () => import('@/EventsPage/EventsPage272')
		},
		{
			name: 'EventsPage273',
			path: '/ProfileBlock/EventsPage273',
			component: () => import('@/EventsPage/EventsPage273')
		},
		{
			name: 'EventsPage274',
			path: '/ProfileBlock/EventsPage274',
			component: () => import('@/EventsPage/EventsPage274')
		},
		{
			name: 'EventsPage275',
			path: '/ProfileBlock/EventsPage275',
			component: () => import('@/EventsPage/EventsPage275')
		},
		{
			name: 'EventsPage276',
			path: '/ProfileBlock/EventsPage276',
			component: () => import('@/EventsPage/EventsPage276')
		},
		{
			name: 'EventsPage277',
			path: '/ProfileBlock/EventsPage277',
			component: () => import('@/EventsPage/EventsPage277')
		},
		{
			name: 'EventsPage278',
			path: '/ProfileBlock/EventsPage278',
			component: () => import('@/EventsPage/EventsPage278')
		},
		{
			name: 'EventsPage279',
			path: '/ProfileBlock/EventsPage279',
			component: () => import('@/EventsPage/EventsPage279')
		},
		{
			name: 'EventsPage280',
			path: '/ProfileBlock/EventsPage280',
			component: () => import('@/EventsPage/EventsPage280')
		},
		{
			name: 'EventsPage281',
			path: '/ProfileBlock/EventsPage281',
			component: () => import('@/EventsPage/EventsPage281')
		},
		{
			name: 'EventsPage283',
			path: '/ProfileBlock/EventsPage283',
			component: () => import('@/EventsPage/EventsPage283')
		},
		{
			name: 'EventsPage285',
			path: '/ProfileBlock/EventsPage285',
			component: () => import('@/EventsPage/EventsPage285')
		},
		{
			name: 'EventsPage286',
			path: '/ProfileBlock/EventsPage286',
			component: () => import('@/EventsPage/EventsPage286')
		},
		{
			name: 'EventsPage287',
			path: '/ProfileBlock/EventsPage287',
			component: () => import('@/EventsPage/EventsPage287')
		},
		{
			name: 'EventsPage288',
			path: '/ProfileBlock/EventsPage288',
			component: () => import('@/EventsPage/EventsPage288')
		},
		{
			name: 'EventsPage289',
			path: '/ProfileBlock/EventsPage289',
			component: () => import('@/EventsPage/EventsPage289')
		},
		{
			name: 'EventsPage290',
			path: '/ProfileBlock/EventsPage290',
			component: () => import('@/EventsPage/EventsPage290')
		},
		{
			name: 'EventsPage291',
			path: '/ProfileBlock/EventsPage291',
			component: () => import('@/EventsPage/EventsPage291')
		},
		{
			name: 'EventsPage292',
			path: '/ProfileBlock/EventsPage292',
			component: () => import('@/EventsPage/EventsPage292')
		},
	]
})

export default router